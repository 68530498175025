<template>
  <div>
    <van-popup v-model="show" @closed="closed" round :style="{ minWidth: '80%' }">
      <div style="background-color: white;  display: flex;flex-direction: column;margin-bottom: 0.5rem;">
        <div style="width: 100%; text-align: center;text-align: center; margin-top: 0.5rem;">
          <p style="font-weight: 600; color: black;font-size: 1rem;">说明：</p>
        </div>
        <div style="overflow-y: scroll;margin-top: 0.5rem; font-size: 0.9rem;padding: 0 0.5rem;">
          <p style="margin: 0.2rem 0.2rem 0.2rem 0.5rem; color: black;">
            升级后点数依据原产品属性及兑换比例由系统自动计算，升级后点数以此页面展示的信息为准，如有疑问可咨询客服。
          </p>
        </div>
      </div>
      <van-button type="primary" color="#D59A54" class="width-100 btn" @click="closed"
        style="font-size: 1rem;">确认</van-button>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
  },
  data() {
    return {
      selectIndex: 0,
      show: true,
      value: 1,
    
    };
  },
  components: {},
  methods: {

    closed() {
      this.$emit("closed", false);
    },
  },
  mounted() {
    // console.log(this.good);
    // 进入页面显示在顶部
    window.scroll(0, 0);
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.img {
  width: 5rem;
}

.tag {
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
  border: 1px solid #666;
  color: #666;
  margin: 0.2rem 1rem;
}

.selectTag {
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
  margin: 0.2rem 1rem;
  border: 1px solid #D33B2E;
  color: #D33B2E;
}

.btn {
  height: 2.5rem;
  margin-top: 0.5rem;
}
</style>