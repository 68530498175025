<template>
  <div class="container">
    <div style="overflow-y: scroll; height: 100vh;">
      <div class="img"></div>
      <div style=" margin-top: -55vh;">
        <p style="font-size: 1.1rem;font-weight: 600; color:  #a57840; margin-left: 1rem;margin-top: 1.5rem;"> 升级规则</p>
        <div class="topbak">
          <p>❶ 原产品支持的商品范围不变，在原产品基础上增加商品品类，扩大选择范围。</p>
          <p style="margin-top: 0.5rem;">❷ 升级后增加的品类有：米面粮油、生鲜果蔬、零食饮料、牛奶肉蛋、日用百货、个护美妆等20余品类，数万种商品。</p>
        </div>
        <div class="centerbak">
          <div style="display: flex;flex-direction: row; justify-content: space-between;">
            <span style="font-size: 1rem; color: black;font-weight: 600;">待升级卡信息</span>
            <span style="color: #a06319;font-size: 0.8rem;">卡号：{{ cardData?.cardNo }}</span>
          </div>
          <div
            style="display: flex;flex-direction: row;background-color: white;border-radius: 0.5rem;align-items: center;margin-top: 1rem;">
            <div style="display: flex;flex-direction: column; justify-content: center;align-items: center;flex: 1;">
              <span style="color: #666;font-size: 0.9rem;margin-top: 0.5rem;"> 卡名称</span>
              <span style="margin-top: 0.5rem;padding:0 0.5rem 0.5rem 0.5rem;font-size: 0.9rem;"> {{ cardData?.cardName
              }}</span>
            </div>
            <div style="width: 1px;height: 3rem;background-color: #999;"></div>
            <div style="display: flex;flex-direction: column; justify-content: center;align-items: center;flex: 1;">
              <span style="color: #666;font-size: 0.9rem;margin-top: 0.5rem;"> {{ newTag }}</span>
              <div style="color: #D33B2E; margin-top: 0.5rem;padding-bottom: 0.5rem;">
                <span style="font-size: 1.1rem;"> {{ cardData?.cardRemainingPoint }}</span>
                <span v-if="ticketType && ticketType == 'E3'" style="font-size: 0.8rem">票</span>
                <span v-else style="font-size: 0.8rem">点</span>

              </div>
            </div>
            <div style="width: 1px;height: 3rem;background-color: #999;"></div>
            <div style="display: flex;flex-direction: column; justify-content: center;align-items: center;flex: 1;">
              <span style="color: #666;font-size: 0.9rem;margin-top: 0.5rem;"> 有效期</span>
              <span style="margin-top: 0.5rem;padding-bottom: 0.5rem;font-size: 0.9rem;"> {{
                $moment(cardData?.expireDate).format("YYYY-MM-DD") }}</span>
            </div>
          </div>

        </div>
        <div class="bottombak">
          <div style="display: flex;flex-direction: row; justify-content: space-between;">
            <span style="font-size: 1rem; color: black;font-weight: 600;">升级后卡信息</span>
          </div>
          <div
            style="display: flex;flex-direction: row;background-color: white;border-radius: 0.5rem;align-items: center;margin-top: 1rem;">
            <div style="display: flex;flex-direction: column; justify-content: center;align-items: center;flex: 1;">
              <span style="color: #666;font-size: 0.9rem;margin-top: 0.5rem;"> 卡名称</span>
              <span style="margin-top: 0.5rem;padding:0 0.5rem 0.5rem 0.5rem;font-size: 0.9rem;"> {{ cardData?.cardName
              }}</span>
            </div>
            <div style="width: 1px;height: 3rem;background-color: #999;"></div>
            <div style="display: flex;flex-direction: column; justify-content: center;align-items: center;flex: 1;">
              <div style="margin-top: 0.5rem;">
                <span style="color: #666;font-size: 0.9rem;"> {{ afterTag }}</span>
                <van-icon style="margin-left: 0.2rem;" name="warning-o" color="#D33B2E" @click="showLog" />
              </div>
              <div style="color: #D33B2E; margin-top: 0.5rem;padding-bottom: 0.5rem;">
                <span style="font-size: 1.1rem;"> {{ cardData?.upgradeCardNum }}</span>

                <span style="font-size: 0.8rem">点</span>
              </div>
            </div>
            <div style="width: 1px;height: 3rem;background-color: #999;"></div>
            <div style="display: flex;flex-direction: column; justify-content: center;align-items: center;flex: 1;">
              <span style="color: #666;font-size: 0.9rem;margin-top: 0.5rem;"> 有效期</span>
              <span style="margin-top: 0.5rem;padding-bottom: 0.5rem;font-size: 0.9rem;"> {{
                $moment(cardData?.expireDate).format("YYYY-MM-DD") }}</span>
            </div>
          </div>

        </div>
        <div style="font-size: 0.8rem;padding: 0 1rem;padding-bottom: 5rem;">

          <p>用户须知：</p>
          <p style="margin-top: 1rem;">1. 用户可根据个人需求自主选择升级；</p>
          <p style="margin-top: 0.5rem;">2. 升级时原卡/券余额归零，升级后不可还原，请谨慎操作；</p>
          <p style="margin-top: 0.5rem;">3. 升级后请按提示入口进入卡/券列表进行使用；</p>
          <p style="margin-top: 0.5rem;">4. 升级后卡/券有效期和原卡/券有效期保持一致；</p>
        </div>
      </div>

      <div class="buttomBt">
        <div class="more" @click="update">
          立即升级
        </div>
      </div>
    </div>
    <div v-if="success" class="updateSuccess">
      <div style="width: 100%;text-align: center;">

        <van-icon name="checked" size="7rem" color="#6BBF7E" />
        <p style="font-size: 1.2rem;margin-top: 0.5rem;">升级福利卡成功</p>
      </div>

      <div style="margin: 2rem 1rem;color: #666;"> 升级成功！卡/券号码：{{ cardData?.cardNo }}已绑定至手机号{{ LoginPhone }}账户中。</div>
      <div style="margin: 1rem;">
        <p style="font-size: 1rem;font-weight: 600;"> 如何查询及使用升级后的福利卡/券？ </p>
        <p style="font-size: 0.9rem;color: #999;margin-top: 0.8rem;"> 1.通过微信搜索公众号“十节优选”并关注； </p>
        <p style="font-size: 0.9rem;color: #999;margin-top: 0.5rem;"> 2.点击公众号底部菜单“优选福利”-“我的” </p>
        <p style="font-size: 0.9rem;color: #999;margin-top: 0.5rem;"> 3.通过手机号{{ LoginPhone }}获取验证码登录； </p>
        <p style="font-size: 0.9rem;color: #999;margin-top: 0.5rem;"> 4.登录后点击“福利卡”可查询余额及使用； </p>
        <p style="font-size: 0.9rem;color: #999;margin-top: 0.5rem;"> 5.如遇不成功，请咨询客服。 </p>
      </div>
      <div style="display: flex;flex-direction: row; margin-top: 2rem;">
        <span class="butRight" @click="goHome(false)">继续升级</span>
        <span class="butRight" @click="goHome(true)">查看已升级福利卡</span>
      </div>

    </div>
    <get-know v-if="show" @closed="closeds"></get-know>
  </div>
</template>

<script>
import GetKnow from '../../components/card/GetKnow'
export default {
  name: 'UpdateCard',
  data() {
    return {
      topbac: require('../../assets/images/update_top.png'),
      cardData: null,
      success: false,
      LoginPhone: null,
      show: false,
      newTag: '当前剩余点数',
      afterTag: '升级后点数',
      ticketType: undefined
    }
  },
  created() { },
  components: { GetKnow },
  methods: {
    showLog() {
      this.show = true

    },
    closeds() {
      this.show = false
    },
    update() {
      this.$api
        .cardUpgrade(this.cardData.cardId)
        .then((res) => {
          if (res.code == 0) {
            this.$Toast.success('升级成功');
            this.success = true
          } else {
            this.$Toast(res.msg);
          }
        })
        .catch((err) => {
        });
    },
    goHome(val) {
      if (val) {
        this.$router.replace({
          path: "/",
          query: {
            ticketType: 'E4'
          }
        });
      } else {
        this.$router.replace({
          path: "/",
        });
      }

    }


  },
  mounted() {
    this.LoginPhone = localStorage.getItem('loginphone')
    this.cardData = JSON.parse(localStorage.getItem('cardItem'))
    this.ticketType = this.$route.query.ticketType
    if (this.ticketType && this.ticketType == 'E3') {
      this.newTag = '当前剩余票数'
    }
  },
}

</script>
<style  lang="scss" scoped>
.topbackimage {

  background-size: 100% 32rem;
  background-repeat: no-repeat;
  height: 32rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;

  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.container {
  height: 100vh;
  overflow: hidden;
  background-color: #E6D38B;
}

.img {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/update_top.png");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
}

.topbak {
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem;
  background-image: linear-gradient(left, #ffc585 0%, #d59a54 100%);
  color: #886337;
}

.butRight {
  width: 100%;
  margin-top: 2rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  border-radius: 1.5rem;
  color: #fff;
  flex: 1;
  margin: 0 1rem;
  text-align: center;
  background-image: linear-gradient(left, #ffc585 0%, #d59a54 100%);
  font-size: 1rem;
}

// .butLeft {
//   width: 100%;
//   margin-top: 2rem;
//   height: 2rem;
//   margin: 0 1rem;
//   text-align: center;
//   line-height: 2rem;
//   border-radius: 1.5rem;
//   color: #fff;
//   flex: 1;
//   text-align: center;
//   background-image: linear-gradient(left, #f2867c 0%, #FFC585 100%);
//   font-size: 1rem;
// }

.centerbak {
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem;
  background-image: linear-gradient(top, #fae3cb 0%, #facd96 100%);
}

.bottombak {
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem;
  background-image: linear-gradient(top, #f4bc81 0%, #f9d9b2 100%);
}

.buttomBt {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  height: 3rem;
  padding-top: 0.5rem;
  align-items: center;
}

.more {
  height: 3rem;
  border-radius: 1.5rem;
  line-height: 3rem;
  width: 90%;
  margin-left: 5%;
  font-size: 1rem;
  background-image: linear-gradient(#f7c289 0%, #f5a94d 100%);
  color: #f5f5f5;
  text-align: center;
}

.updateSuccess {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: #fff;

  padding-top: 2rem;
}
</style>